import React, { useContext, useEffect, useRef, useState } from 'react'
import Header from './Header'
import Balance from './Balance'
import { Appstate } from '../App'
import { ethers } from 'ethers'
import { message, Modal } from 'antd'
import { TailSpin } from 'react-loader-spinner'
import { useTimer } from 'react-timer-hook'
import ROIJSON from '../artifacts/contracts/ROI.sol/Oceanic.json'

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const erc20Abi = [
    "function balanceOf(address) view returns (uint256)",
    "function transfer(address, uint256) returns (bool)",
    "function allowance(address owner, address spender) view returns (uint256)",
    "function approve(address, uint256) returns (bool)",
    "function transferFrom(address, address, uint256) returns (bool)",
  ];

const ROI = () => {
    const {ethereum} = window;
    const intervalRef = useRef(null);
    const useAppState = useContext(Appstate);
    const [bal, setBal] = useState({
        usdt: 0,
        bnb: 0
    })
    const [amount, setAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);
    const [withdrawLoading, setWithdrawLoading] = useState(false);
    const [claimLoading, setClaimLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [user, setUser] = useState({
        directTeam: 0,
        totalTeam: 0,
        package: 0,
        roiClaimable: 0,
        totalIncome: 0,
        withdrawable: 0,
        levelIncome: 0,
        roiIncome: 0,
        lastClaimed: 0,
        withdrawTimer: 0,
        maxClaimTimer: 0
    })
    const [time, setTime] = useState(0);
    const [show, setShow] = useState(false);

    const [amount2, setAmount2] = useState("");

    let provider;
    if(ethereum !== undefined) {
      provider = new ethers.providers.Web3Provider(window.ethereum);
    } else {
      provider = new ethers.providers.JsonRpcProvider();
    }

    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract("0x55d398326f99059fF775485246999027B3197955", erc20Abi, signer);
    const roiContract = new ethers.Contract("0x9593B33396E3660946fd14241e1930e9E5f83Faa", ROIJSON.abi, signer);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (Number(user.lastClaimed) > 0) {
                setTime(Date.now() - Number(user.lastClaimed) * 1000);
            } else {
                setTime(0);
            }
        }, 10);
        return () => clearInterval(intervalRef.current);
    }, [user.lastClaimed]);

    useEffect(() => {
        async function getData() {
            let user = await roiContract.id(useAppState.walletAddress);
            let zeroAddr = 0;
            if (Number(user) == zeroAddr) {
                setModalOpen(true);
            } else {
                setModalOpen(false);
            }
        }
        getData();
    }, [useAppState.walletAddress, useAppState.change, useAppState.id]);

    const convert = (amount) => {
        return Number(ethers.utils.formatEther(amount.toString()));
      }

    useEffect(() => {
        async function getData() {
            setShow(false);
            let _id = Number(await roiContract.id(useAppState.walletAddress));
            let provider = new ethers.providers.JsonRpcProvider(window.ethereum);
            let bal = await tokenContract.balanceOf(useAppState.walletAddress);
            let bal2 = await provider.getBalance(useAppState.walletAddress);
            setBal({
                bnb: Number(ethers.utils.formatEther(bal2.toString())).toFixed(2),
                usdt: Number(ethers.utils.formatEther(bal.toString())).toFixed(2)
            })

            let _user = await roiContract.userInfo(_id);
            let _claimable = await roiContract.getClaimableROI(_id);
            setUser({
                directTeam: Number(_user.directTeam),
                totalTeam: Number(_user.totalTeam),
                package: convert(_user.package),
                roiClaimable: convert(_claimable),
                totalIncome: convert(_user.totalIncome),
                withdrawable: convert(_user.withdrawable),
                levelIncome: convert(_user.levelIncome),
                roiIncome: convert(_user.roiIncome),
                lastClaimed: Number(_user.lastClaimed),
                withdrawTimer: Number(await roiContract.getUserROICountdown(_id)),
                // maxClaimTimer: Number(await roiContract.getWithdrawTime()),
            })
           
            setShow(true);
        }
        getData();
    }, [useAppState.id, useAppState.walletAddress, useAppState.change])

    const register = async () => {
        setRegisterLoading(true);
        try {
            let _refs = [];
            let _ids = [];

            let provider = new ethers.providers.JsonRpcProvider("https://rpc.coredao.org");
            let contract = new ethers.Contract(useAppState.mainAddr, useAppState.Blockocean.abi, provider);
            let _id = await contract.ids(useAppState.walletAddress);
            let _userInfo = await contract.userInfo(Number(_id));

            if(Number(_id) == 0) {
                message.error("User Not registered in Blockocean")
                return;
            }

            let upline = Number(_userInfo.referrer);
            for (let i = 0; i < 15; i++) {
                if (upline == 0) break;
                let _curUserInfo = await contract.userInfo(upline);
                _ids.push(upline);
                _refs.push(_curUserInfo.account);

                upline = Number(_curUserInfo.referrer)
            }

            let tx = await roiContract.register(useAppState.walletAddress, Number(_id), _refs, _ids);
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            message.success("Sucessfully Registered");
        } catch (error) {
            message.error(error.reason)
        }
        setRegisterLoading(false)
    }

    const deposit = async () => {
        setLoading(true);
        try {
            if (convert(await tokenContract.allowance(useAppState.walletAddress, useAppState.roiAddr)) < 1000000) {
                let allowance = await tokenContract.approve(useAppState.roiAddr, ethers.utils.parseEther("10000000000"))
                await allowance.wait();
            }

            let provider = new ethers.providers.JsonRpcProvider("https://rpc.coredao.org");
            let contract = new ethers.Contract(useAppState.mainAddr, useAppState.Blockocean.abi, provider);
            let _id = await contract.ids(useAppState.walletAddress);

            let _userInfo = await contract.userInfo(_id);
            let tx = await roiContract.deposit(_id, ethers.utils.parseEther(amount), Number(_userInfo.slots));
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            setAmount("");
            message.success("Sucessfully Deposited");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

    const claim = async () => {
        setClaimLoading(true);
        try {
            let tx = await roiContract.claimROI(await roiContract.id(useAppState.walletAddress));
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            setAmount("");
            message.success("Sucessfully Claimed");
        } catch (error) {
            message.error(error.reason);
        }
        setClaimLoading(false);
    }

    const withdraw = async (_type) => {
        setWithdrawLoading(true);
        try {
            let _userInfo = await useAppState.contract.userInfo(useAppState.id);
            let tx = await roiContract.withdraw(_type, ethers.utils.parseUnits(amount2.toString(), "mwei"), Number(_userInfo.slots));
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            setAmount("");
            message.success("Sucessfully Withdrawn");
        } catch (error) {
            message.error(error.reason);
        }
        setWithdrawLoading(false);
    }

    const formatTime = (time) => {
        const sec = Math.floor((time / 1000) % 60);
        const min = Math.floor((time / 60000) % 60);
        const hr = Math.floor((time / 3600000) % 24);
        const days = Math.floor(time / 86400000);
          return (
            `${days.toString().padStart(2, '0')} Days, ` +
            `${hr.toString().padStart(2, '0')} : ` +
            `${min.toString().padStart(2, '0')} : ` +
            `${sec.toString().padStart(2, '0')}`
          );
      };

    let OkText = registerLoading ? <TailSpin height={14} color='white' /> : <p>Register</p>;

    // if(true) return (
    //     <div className="flex justify-center p-4">
    //         <div className="md:w-4/5 w-full font-semibold">
    //             <Header />

    //     <div className="flex items-center justify-center mt-12 text-white text-center px-4">
    //         <div className="max-w-lg">
    //           <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-blue-700 text-transparent bg-clip-text">Oceanic</h1>
    //           <p className="text-lg mb-6 bg-gradient-to-r from-blue-300 to-blue-500 text-transparent bg-clip-text">We're launching something amazing soon. Stay tuned!</p>
    //           <div className="border border-white rounded-full px-6 py-2 inline-block">
    //             <span className="text-sm">Coming Soon</span>
    //           </div>
    //         </div>
    //     </div>

    //     </div>
    //     </div>
    // )

    return (
        <div className="flex justify-center p-4">
            <div className="md:w-4/5 w-full font-semibold">
                <Header />

                <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-2xl shadow-lg profile">
                    <div className='flex'><span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1"><img src='usdt.png' className='h-6 mr-2' />  ${bal.usdt}</span></div>
                    <div className='flex'><span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1"><img src='https://cryptologos.cc/logos/bnb-bnb-logo.png' className='h-6 mr-2' />  {bal.bnb}</span></div>
                </div>

                <Modal title="Register" open={modalOpen} closable={false} onOk={register} okText={OkText} okButtonProps={{ className: "bg-blue-500" }} cancelButtonProps={{ className: 'hidden' }} >
                    <div className='flex w-full flex-col justify-center items-center'>
                        <img src='logo.png' className='h-32' />
                    </div>
                </Modal>

                <div className="mt-6 flex justify-between items-center w-full bg-[#8080821a] px-3 py-2 rounded-lg shadow-lg wallet">
                    <div>
                        <p className='text-yellow-400'>Package</p>
                        <p className='text-lg'>${user.package}</p>
                    </div>
                    <img src='usdt.png' className='h-8' />
                </div>

                {/* <div className="mt-6 flex justify-between items-center w-full bg-[#8080821a] px-3 py-2 rounded-lg shadow-lg wallet">
                    <div>
                        <p className='text-yellow-400'>Withdraw Timer Countdown</p>
                        {show && <p className='text-lg'><MyTimer expiryTimestamp={user.maxClaimTimer * 1000} /></p>}
                    </div>
                    <img src='logo.png' className='h-12' />
                </div> */}

                <div className="mt-6 flex justify-between items-center w-full bg-[#8080821a] p-4 rounded-lg shadow-lg wallet">
                    <div className='w-full'>
                        <input value={amount} onChange={(e) => setAmount(e.target.value)} className='w-full p-2 text-gray-700 rounded-sm outline-none' placeholder='Enter Amount' />
                        <button onClick={deposit} className='flex justify-center items-center bg-lime-500 mt-4 hover:bg-lime-600 p-2 w-full rounded-sm text-white text-sm'>{loading ? <TailSpin height={18} color='white' /> : "Deposit"}</button>
                    </div>
                </div>

                <div className='bg-[#8080821a] autopool p-3 mt-6 rounded-lg flex justify-between items-center'>

                    <div className='flex flex-col items-start'>
                        <p className='text-sm text-yellow-400'>Claim Time</p>
                        <p className='text-white font-bold'>{formatTime(time)}</p>

                        <p className='text-sm mt-4 text-yellow-400'>Last Claimed</p>
                        <p className='text-white font-bold'>{new Date(user.lastClaimed * 1000).toLocaleString()}</p>
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className='text-sm text-yellow-400'>Claimable</p>
                        <p className='text-white font-bold'>${user.roiClaimable}</p>

                        <button onClick={claim} className='mt-4 flex justify-center items-center bg-green-500 hover:bg-green-500 py-2 px-4 w-full rounded-sm text-white text-sm'>{claimLoading ? <TailSpin height={18} color='white' /> : "Claim"}</button>
                    </div>
    
                </div>

                <div className="flex justify-between">
                    <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-4 rounded-2xl shadow-lg profile">
                        <div className="font-bold">
                            <p className="">Total Income</p>
                            <p className="text-xl">${user.totalIncome}</p>
                        </div>
                        <img src="usdt.png" className="h-14" />
                    </div>

                    <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-4 rounded-2xl shadow-lg profile">
                        <div className="font-bold">
                            <p className="">Level Income</p>
                            <p className="text-xl">${user.levelIncome}</p>
                        </div>
                        <img src="usdt.png" className="h-16" />
                    </div>
                </div>

                <div className="flex justify-between">
                    <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-4 rounded-2xl shadow-lg profile">
                        <div className="font-bold">
                            <p className="">Direct Investors</p>
                            <p className="text-xl">{user.directTeam}</p>
                        </div>
                        <img src="direct.png" className="h-14" />
                    </div>

                    <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-4 rounded-2xl shadow-lg profile">
                        <div className="font-bold">
                            <p className="">Total Downline Investors</p>
                            <p className="text-xl">{user.totalTeam}</p>
                        </div>
                        <img src="teams.png" className="h-16" />
                    </div>
                </div>
                
                <input value={amount2} onChange={(e) => setAmount2(e.target.value)} className='w-full mt-6 p-2 text-gray-700 rounded-lg outline-none' placeholder='Enter Amount to Withdraw' />
                <div className="mt-2 flex justify-between items-center w-full bg-[#8080821a] px-3 py-2 rounded-lg shadow-lg wallet">
                    <div>
                        <p className='text-yellow-400'>Withdrawable</p>
                        <p className='text-lg'>${user.withdrawable}</p>
                    </div>
                    <div className='flex items-center'>
                        <button onClick={() => withdraw(0)} className='flex justify-center items-center bg-green-500 hover:bg-green-600 py-2 px-4 rounded-sm text-white text-sm'>{withdrawLoading ? <TailSpin height={18} color='white' /> : "Withdraw"}</button>
                        <button onClick={() => withdraw(1)} className='ml-2 flex justify-center items-center bg-green-500 hover:bg-green-600 py-2 px-4 rounded-sm text-white text-sm'>{withdrawLoading ? <TailSpin height={18} color='white' /> : "Reinvest"}</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ROI