import React, { useContext, useState, useEffect } from 'react'
import Header from './Header'
import { Appstate } from '../App';
import ROIJSON from '../artifacts/contracts/ROI.sol/Oceanic.json'
import { ethers } from 'ethers';

const RoiTeams = () => {
    const useAppState = useContext(Appstate);
    const [data, setData] = useState([
        {
            sno: 0,
            id: 0,
            Address: "0",
            started: 0,
            package: 0,
            referrer: 0
        },
    ]);
    const [layer, setLayer] = useState(1);

    useEffect(() => {
        async function getData() {
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            const roiContract = new ethers.Contract("0x9593B33396E3660946fd14241e1930e9E5f83Faa", ROIJSON.abi, provider);
            let _team = await roiContract.getTeamUsers(
                useAppState.id, layer - 1
            );
        
            setData([]);
            for (let i = 0; i < _team.length; i++) {
                setData((prev) => [
                    ...prev,
                    {
                        sno: i + 1,
                        id: Number(_team[i].ID),
                        Address: _team[i].account,
                        started: Number(_team[i].start),
                        package: useAppState.convert(_team[i].package),
                        referrer: Number(_team[i].referrer)
                    },
                ]);
            }
        }
        getData();
    }, [useAppState.walletAddress, useAppState.change, useAppState.id, layer]);

    // if(true) return (
    //     <div className="flex justify-center p-4">
    //         <div className="md:w-4/5 w-full font-semibold">
    //             <Header />

    //     <div className="flex items-center justify-center mt-12 text-white text-center px-4">
    //         <div className="max-w-lg">
    //           <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-blue-700 text-transparent bg-clip-text">Oceanic</h1>
    //           <p className="text-lg mb-6 bg-gradient-to-r from-blue-300 to-blue-500 text-transparent bg-clip-text">We're launching something amazing soon. Stay tuned!</p>
    //           <div className="border border-white rounded-full px-6 py-2 inline-block">
    //             <span className="text-sm">Coming Soon</span>
    //           </div>
    //         </div>
    //     </div>

    //     </div>
    //     </div>
    // )


    return (
        <div className="flex w-full justify-center p-4">
            <div className='w-full flex flex-col items-center'>
                <div className="md:w-4/5 w-full font-semibold">
                    <Header />
                </div>

                <div className="md:w-4/5 w-full flex justify-center p-4">
                    <div className="w-full mt-6">
                        <div className='flex overflow-x-auto'>
                            <p onClick={() => setLayer(1)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 1 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-0`}>1</p>
                            <p onClick={() => setLayer(2)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 2 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-2`}>2</p>
                            <p onClick={() => setLayer(3)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 3 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-2`}>3</p>
                            <p onClick={() => setLayer(4)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 4 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-2`}>4</p>
                            <p onClick={() => setLayer(5)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 5 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-2`}>5</p>
                            <p onClick={() => setLayer(6)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 6 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-2`}>6</p>
                            <p onClick={() => setLayer(7)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 7 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-2`}>7</p>
                            <p onClick={() => setLayer(8)} className={`cursor-pointer font-medium px-4 py-2 rounded-sm ${layer == 8 ? "bg-blue-500" : "bg-gray-700 bg-opacity-45"} ml-2`}>8</p>
                        </div>

                        <div class="overflow-x-scroll mt-4 w-full rounded-lg dark:text-white text-gray-800">
                            <table class="w-full whitespace-nowrap">
                                <thead class="bg-[#8080821a]">
                                    <th class="text-center font-bold py-3 px-2 border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)]">SNo.</th>
                                    <th class="text-center font-bold py-3 px-2 border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)]">ID</th>
                                    <th class="text-center font-bold py-3 px-2 border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)]">Invested</th>
                                    <th class="text-center font-bold py-3 px-2 border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)]">Address</th>
                                    <th class="text-center font-bold py-3 px-2 border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)]">Sponser ID</th>
                                    <th class="text-center font-bold py-3 px-2 border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)]">Activation Date</th>
                                </thead>
                                {data.map((e, i) => {
                                    return (
                                        <tr key={i} class="">
                                            <td class="text-center justify-center border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] py-3 px-2">
                                                {e.sno}
                                            </td>

                                            <td class="text-center py-3 px-2 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)]">
                                                {e.id}
                                            </td>
                                            <td class="text-center py-3 px-2 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)]">
                                                ${e.package}
                                            </td>
                                            <td class="text-center py-3 px-2 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)]">
                                                {e.Address.slice(0, 7)}...{e.Address.slice(37)}
                                            </td>
                                            <td class="text-center py-3 px-2 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)]">
                                                {e.referrer}
                                            </td>
                                            <td class="text-center py-3 px-2 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)]">
                                                {useAppState.getUTCTime(e.started * 1000)}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoiTeams