import CircleIcon from '@mui/icons-material/Circle';
import { useContext, useEffect, useState } from 'react';
import {Appstate} from '../App'
import { message } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import VerifiedIcon from '@mui/icons-material/Verified';

const Slots = () => {
    const useAppState = useContext(Appstate)
    const [slots, setSlots] = useState(0);
    const [Au, setAu] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [levels, setLevels] = useState([51/10000, 102/10000, 252/10000, 502/10000, 1002/10000, 2502/10000, 5002/10000])
    const [levels, setLevels] = useState([66, 102, 252, 502, 1002, 2502, 5002])
    const [selected, setSelected] = useState({
        "0": false,
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false
      })
      const [lvl, setLvl] = useState({
        lvl: 0,
        total: 0
      })
      const [amtCore, setAmtCore] = useState(0);
      const [amtLoading, setAmtLoading] = useState(false);

      useEffect(() => {
        async function getData() {
          setAmtLoading(true);
          if(Number(lvl.total) > 0) {
            let _amtInCore = await useAppState.contract.getPriceFromUniswapV2(ethers.utils.parseUnits((Number(lvl.total) - Au).toString(), "mwei"));
            let _finalAmt = Number(ethers.utils.formatEther(_amtInCore, "mwei"));
            setAmtCore(_finalAmt + ((_finalAmt/100) * 0.2));
            // let _amtInCore = await useAppState.contract.getPriceFromUniswapV2(ethers.utils.parseUnits("0.01", "mwei"));
            // let _finalAmt = Number(ethers.utils.formatEther(_amtInCore));
            // setAmtCore(_finalAmt);
          }
          setAmtLoading(false);
        }
        getData();
      },[lvl.total, useAppState.walletAddress, useAppState.change, useAppState.id])

      const buySlot = async (_slot) => {
        setLoading(true);
        try {
            // if(useAppState.convert(await useAppState.tokenContract.allowance(useAppState.walletAddress, useAppState.mainAddr)) < 1000000) {
            //     let allowance = await useAppState.tokenContract.approve(useAppState.mainAddr, ethers.utils.parseEther("10000000000")) 
            //     await allowance.wait();
            // }

            let _amtInCore = await useAppState.contract.getPriceFromUniswapV2(ethers.utils.parseUnits((Number(lvl.total) - Au).toString(), "mwei"));
            let amtInNum = ethers.utils.formatEther(_amtInCore);
            let tx = await useAppState.contract.buySlot(useAppState.id, {
              value: ethers.utils.parseEther((Number(amtInNum) + ((Number(amtInNum)/100) * 0.1)).toString()),
            });

            await tx.wait();

            setLvl({
                lvl: 0,
                total: 0
            })

            useAppState.setChange(useAppState.change + 1);
            message.success("Sucessfully Purchased")
        } catch (error) {
            message.error(error.reason)
            console.log(error);
        }
        setLoading(false);
    }

    const select = (amt, i) => {
        if(selected[i - 1] == true || i == slots) {
          setLvl((prev) => ({lvl: prev.lvl + 1, total: prev.total + amt})); 
          setSelected((prev) => ({...prev, [i]: true}));
        }
      }
      
      const deSelect = (amt, i) => {
        if(selected[i + 1] == false || i == useAppState.levels.length - 1) {
          setLvl((prev) => ({lvl: prev.lvl - 1, total: prev.total - amt})); 
          setSelected((prev) => ({...prev, [i]: false}));
        }
      } 

    useEffect(() => {
        async function getData() {
            let _userInfo = await useAppState.contract.userInfo(useAppState.id);
            let _rewardInfo = await useAppState.contract.rewardInfo(useAppState.id);
            setSlots(Number(_userInfo.slots));
            setAu(useAppState.convert(_rewardInfo.selfUpgrade))
        }   
        getData();
    },[useAppState.walletAddress, useAppState.change, useAppState.id])

  return (
    <div className='w-full font-bold'>
        <div className="flex flex-col items-center px-4 md:p-0 mt-2">
          <div className="w-full flex justify-start gap-x-4 overflow-x-auto">
            {levels.map((e, i) => {
              let amt = e;
              return (
                <div
                  onClick={() => {
                    i+1 <= slots ? message.success("Slot Active") : i+1 == slots + 1 ? (selected[i] ? deSelect(amt, i) : select(amt, i)) : message.warning("Invalid Slot Select")
                  }}
                  className={`${i >= 0 ? "" : "hidden"} flex-col cursor-pointer blue_blur wallet p-4 mt-4 font-bold text-black dark:text-white min-w-[130px] min-h-32 flex justify-center items-center rounded-full bg-white dark:bg-[#1e2026] shadow-lg`}
                >
                  <p className="bg-gray-200 rounded-full text-gray-800 min-w-8 flex justify-center items-center text-sm p-1 mt-1">
                    Slot - {i+1}{" "}
                    {selected[i] || slots >= i+1 ? <span className="ml-1">✅</span> : null}
                  </p>
                  <p className="text-gray-800 dark:text-[#FFE900] mt-1">
                    ${amt}
                  </p>
                  <img src="matictoken.png" className="h-8 mt-1" />
                </div>
              );
            })}
          </div>
        </div>

        {lvl.lvl > 0 ? (
          <div className="flex justify-center px-4 md:p-0 mt-8">
            <div className="w-full">
              <button
                onClick={() => buySlot(slots + 1)}
                className="w-full button-29 flex justify-center items-center cursor-pointer rounded-sm text-sm font-bold py-3 px-3 text-white"
              >
                {loading ? (
                  <TailSpin height={15} color="white" />
                ) : (
                  amtLoading ? <TailSpin height={18} color='white' /> : <span>Buy Slot <span className='text-[#FFE900]'>{amtCore.toFixed(5)} CORE</span></span>
                  // amtLoading ? <TailSpin height={18} color='white' /> : <span>Buy Slot <span className='text-[#FFE900]'>${lvl.total} USDT</span></span>
                )}
              </button>
            </div>
          </div>
        ) : null}
    </div>
  )
}

export default Slots