import { Routes, Route, Navigate, useLocation, Router, useNavigate } from "react-router-dom";
import { useState, useEffect, createContext } from "react";
import { ethers } from "ethers";
import Dashboard from './Components/Dashboard'
import Register from './Components/Register'
import Homepage from './Components/Homepage'
import Teams from './Components/Teams'
import Wallet from './Components/Wallet'
import Blockocean from '../src/artifacts/contracts/Blockocean.sol/Blockocean.json'
import ROIJSON from '../src/artifacts/contracts/ROI.sol/Oceanic.json'
import Admin from "./Components/Admin";
import ROI from "./Components/ROI";
import RoiTeams from "./Components/RoiTeams";

const switchNetwork = async (chainId, rpcUrl) => {
  if (!window.ethereum) {
    console.error("MetaMask is not installed");
    return;
  }

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(chainId) }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexValue(chainId),
              chainName: chainId === 1116 ? "Core Blockchain" : "Binance Smart Chain",
              rpcUrls: [rpcUrl],
              nativeCurrency: {
                name: chainId === 1116 ? "CORE" : "BNB",
                symbol: chainId === 1116 ? "CORE" : "BNB",
                decimals: 18,
              },
              blockExplorerUrls: [
                chainId === 1116 ? "https://scan.coredao.org" : "https://bscscan.com",
              ],
            },
          ],
        });
      } catch (addError) {
        console.error("Failed to add new chain", addError);
      }
    } else {
      console.error("Failed to switch chain", error);
    }
  }
};

const Appstate = createContext();

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

function App() {
  const {ethereum} = window;
  const Navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState("");
  const [change, setChange] = useState(0);
  const [id, setId] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/oceanic" || location.pathname === "/oceanicteams") {
      switchNetwork(56, "https://bsc-dataseed.binance.org"); // Binance Smart Chain
    } else {
      switchNetwork(1116, "https://rpc.coredao.org"); // Core Blockchain
    }
  }, [location.pathname]);
  
  const convert = (amount) => {
    return Number(ethers.utils.formatUnits(amount.toString(), "mwei"));
  }
  
  // const usdt = "0x7b8bD851d74Ca0eA1eC37AB8aE6fCF3d81cd3108";
  // const usdt = "0x7b8bD851d74Ca0eA1eC37AB8aE6fCF3d81cd3108";
  const usdt = "0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1";
  const mainAddr = "0xA18Bb50E59B4B0cfEb48021f0988aecdE08956f5";
  const roiAddr = "0x9593B33396E3660946fd14241e1930e9E5f83Faa"
  const defaultRefer = "2000";
  const hotWalletAddr = "0x0e173432C962F9fc3296EB29c08F673157dDB068";
  const wallets = 
  [
    '0xF50c45A3d9010F0c921B8d45cC5F2a8C1cC68C47',
    '0xf9449D2147EfFB09C59E3f6cE36D2d7939f04641',
    '0x420b928ea05f2f6a55125249Ca32AFaC82DA50Fb',
    '0x8a7eFAa2B471efE7Af13fDD0e30cB9EED7b97149'
  ]
  let provider;
  if(ethereum !== undefined) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.JsonRpcProvider();
  }

  const signer = provider.getSigner();
  const contract = new ethers.Contract(mainAddr, Blockocean.abi, signer);
  const tokenContract = new ethers.Contract(usdt, erc20Abi, signer);
  const roiContract = new ethers.Contract(roiAddr, ROIJSON.abi, signer);

  useEffect(() => {
    async function req() {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
      // setWalletAddress("0x765873d02c96940b3Ad6bB831A45bADC1882b261");
    }
    req();
  }, []);

  useEffect(() => {
    async function req() {
      setId(Number(await contract.ids(walletAddress)));
    }
    req();
  },[walletAddress, change])

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  function getUTCTime(ms) {
    const now = new Date(ms);

    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1; // Months are zero-indexed
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();

    const utcTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} UTC`;
    return utcTime;
  }

  return (
    <Appstate.Provider value={{getUTCTime,Blockocean, id, setId, usdt, mainAddr, hotWalletAddr, contract, defaultRefer, tokenContract, walletAddress,setWalletAddress, setChange, change, convert, roiContract, roiAddr}}>
      <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/oceanic" element={<ROI />} />
          <Route path="/oceanicteams" element={<RoiTeams />} />
          <Route path="/maple" element={<Admin />} />
      </Routes>
    </Appstate.Provider>
  );
}

export default App;
export {Appstate}
