import React, { useContext, useEffect, useState } from 'react'
import { Appstate } from '../App'
import { message } from 'antd';
import { TailSpin } from 'react-loader-spinner';

const UpdateLevel = () => {
    const useAppState = useContext(Appstate);
    const [isAvl, setIsAvl] = useState(false);
    const [userRank, setUserRank] = useState(0);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        async function getData() {
            let _isAvl = await useAppState.contract.isRankUpdateAvl(useAppState.id);
            setIsAvl(_isAvl);
            let _user = await useAppState.contract.userInfo(useAppState.id);
            setUserRank(Number(_user.rank));
        }
        getData();
    },[useAppState.change, useAppState.walletAddress, useAppState.id])

    const update = async () => {
        setLoading(true);
        try {
            let tx = await useAppState.contract.updateLevel(useAppState.id);
            await tx.wait();
            message.success("Sucessfully Updated");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason)
        }
        setLoading(false);
    }

  return (
    <>
    {isAvl ? 
        <div className='bg-[#8080821a] p-3 mt-3 rounded-lg flex justify-between items-center'>
            <div>
                <img src={`b${userRank + 1}.png`} className='h-20' />
            </div>
            <div className='flex flex-col items-center'>
                <p className='text-xs animate-pulse text-lime-500'>Update Available</p>
                <button onClick={update} className='bg-lime-500 mb-2 w-[120px] mt-4 flex justify-center font-semibold p-2 rounded-lg'>{loading ? <TailSpin height={22} color='white' /> : "Update"}</button>
            </div>
        </div>
    : null}
    </>
  )
}

export default UpdateLevel