import React, { useContext, useEffect, useState, useRef } from 'react'
import { Appstate } from '../App'
import { TailSpin } from 'react-loader-spinner';
import { message } from 'antd';
import { ethers } from 'ethers';

const GlobalPool = () => {
    const useAppState = useContext(Appstate);
    const intervalRef = useRef(null);
    const [isPaid, setIsPaid] = useState(false);
    const [claimable, setClaimable] = useState(0);
    const [teamClaimable, setTeamClaimable] = useState(0);
    const [time, setTime] = useState(0);
    const [lastClaimed, setLastClaimed] = useState(0);
    const [totalGPClaimed, setTotalGPClaimed] = useState(0);
    const [totalGPTeamReward, setTotalGPTeamReward] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [directTeam, setDirectTeam] = useState(0);
    const [GPTeamClaimed, setGPTeamClaimed] = useState(0);
    const [totalTeam, setTotalTeam] = useState(0);
    const [pairs, setPairs] = useState([]);
    const GPTeamReward = [25, 150, 750, 2000, 5000, 12500, 25000, 75000, 125000, 250000];

    useEffect(() => {
        async function getData() {
            let _reward = await useAppState.contract.rewardInfo(useAppState.id);
            setLastClaimed(Number(_reward.GPLastClaimed) * 1000);
            setTotalGPClaimed(useAppState.convert(_reward.totalGPClaimed));
            setTotalGPTeamReward(useAppState.convert(_reward.totalGPTeamReward));
            setIsPaid(await useAppState.contract.isGPPaid(useAppState.id))
            
            setDirectTeam(Number(await useAppState.contract.directGPUsers(useAppState.id)))
            setGPTeamClaimed(Number(_reward.GPTeamClaimed));

            let _claimable = await useAppState.contract.callStatic.ClaimRapid(useAppState.id);
            setClaimable(useAppState.convert(_claimable[0]));
            let arr = [];
            for(let i=0; i<_claimable[1].length; i++) {
              arr.push(useAppState.convert(_claimable[1][i]));
            }
            setPairs(arr);

            let _gp = await useAppState.contract.callStatic.claimExcellence(useAppState.id);
            setTotalTeam(Number(_gp[1]))
            setTeamClaimable(Number(_gp[0]));
        }
        getData();
    },[useAppState.walletAddress, useAppState.id, useAppState.change])

    useEffect(() => {
        intervalRef.current = setInterval(() => {
          if(lastClaimed > 0) {
            setTime(Date.now() - lastClaimed);
          } else {
            setTime(0);
          }
        }, 10);
        return () => clearInterval(intervalRef.current);
      }, [lastClaimed]);
    
      const formatTime = (time) => {
        const sec = Math.floor((time / 1000) % 60);
        const min = Math.floor((time / 60000) % 60);
        const hr = Math.floor((time / 3600000) % 24);
        const days = Math.floor(time / 86400000);
          return (
            `${days.toString().padStart(2, '0')} Days, ` +
            `${hr.toString().padStart(2, '0')} : ` +
            `${min.toString().padStart(2, '0')} : ` +
            `${sec.toString().padStart(2, '0')}`
          );
      };

      const claim = async () => {
        setLoading(true);
        try {
            let tx = await useAppState.contract.ClaimRapid(useAppState.id);
            await tx.wait();
            message.success("Sucessfully Claimed");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason)
        }
        setLoading(false);
      }

      const claim2 = async () => {
        setLoading(true);
        try {
            let tx = await useAppState.contract.claimExcellence(useAppState.id);
            await tx.wait();
            message.success("Sucessfully Claimed");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason)
        }
        setLoading(false);
      }

      const update = async () => {
        setLoading2(true);
        try {
            // if(useAppState.convert(await useAppState.tokenContract.allowance(useAppState.walletAddress, useAppState.mainAddr)) < 1000000) {
            //     let allowance = await useAppState.tokenContract.approve(useAppState.mainAddr, ethers.utils.parseEther("10000000000")) 
            //     await allowance.wait();
            // }

            let _amtInCore = await useAppState.contract.getPriceFromUniswapV2(ethers.utils.parseUnits("15", "mwei"));
            let amtInNum = ethers.utils.formatEther(_amtInCore);
          
            let tx = await useAppState.contract.enterGP(useAppState.id, {
              value: ethers.utils.parseEther((Number(amtInNum) + ((Number(amtInNum)/100) * 0.1)).toString()),
            });
            await tx.wait();
            message.success("Sucessfully Updated");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason)
        }
        setLoading2(false);
      }

  return (
    <div className='w-full mt-8'>
        {isPaid ? 
          <div>
            <div>
              {[10, 110, 610, 1610, 4110, 9110, 19110, 49110, 99110, 199110].map((e, i) => {
                return ( i <= GPTeamClaimed ? 
                  <div className='flex mt-2 justify-between items-center w-full bg-[#8080821a] py-2 px-4 rounded-lg'>
                    <div className='w-1/3 text-start'>
                        <p className='text-sm text-teal-500'>Direct Required</p>
                        <p className='text-start text-lg font-bold'>{directTeam > 5 * (i+1) ? 5 * (i+1) : directTeam} / {5 * (i+1)} {directTeam > 5 * (i+1) ? "✅" : null}</p>
                    </div>
                    <div className='w-1/3 text-center'>
                        <p className='text-sm text-yellow-500'>Team Required</p>
                        <p className='text-center text-lg font-bold'>{totalTeam > e ? e : totalTeam} / {e} {totalTeam > e ? "✅" : null}</p>
                    </div>
                    <div className='w-1/3 text-end'>
                        <p className='text-sm text-lime-500'>Reward</p>
                        <p className='text-end text-lg font-bold'>${GPTeamReward[i]} {GPTeamClaimed > i ? "✅" : null}</p>
                    </div>
                  </div>
                : null)
              })} 
            </div>
            <div className='flex mt-4 justify-between w-full bg-[#8080821a] wallet p-4 rounded-lg'>
                <div>
                    <p className='text-sm'>Claimed</p>
                    <p className='text-lg font-semibold'>${totalGPClaimed} + ${totalGPTeamReward}</p>

                    <p className='mt-4 text-sm'>{new Date(lastClaimed).toLocaleString()}</p>
                    <p className='text-lg font-semibold'>{formatTime(time)}</p>
                </div>
                <div>
                <p className='text-sm'>Claimable</p>
                    <p className='text-lg font-semibold'>${claimable} + ${teamClaimable == 1 ? GPTeamReward[GPTeamClaimed] : 0}</p>
                    <div className='flex flex-row items-center gap-x-1'>
                    {pairs.map((e, i) => {
                      return (
                        <p key={i} className='text-lg font-semibold'>${e} {i == pairs.length - 1 ? "" : "+"}</p>
                      )
                      })}
                    </div>
                    <button onClick={claim} className='bg-lime-500 mb-2 w-[150px] mt-4 flex justify-center font-semibold p-2 rounded-lg'>{loading ? <TailSpin height={22} color='white' /> : "Claim Rapid"}</button>
                    <button onClick={claim2} className='bg-lime-500 mb-2 w-[150px] mt-4 flex justify-center font-semibold p-2 rounded-lg'>{loading ? <TailSpin height={22} color='white' /> : "Claim Excellence"}</button>
                </div>
            </div>
          </div>
        :   
        (
        <div className='flex justify-between items-center w-full bg-[#8080821a] wallet p-4 rounded-lg'>
            <div>
                <p className='text-sm'>Enter In Excellence</p>
                <p className='font-bold text-lg'>$15</p>
            </div>
            <div>
                <button onClick={update} className='bg-lime-500 w-[120px] flex justify-center font-semibold p-2 rounded-lg'>{loading2 ? <TailSpin height={22} color='white' /> : "Pay Here"}</button>
            </div>
        </div>
        )
        }
    </div>
  )
}

export default GlobalPool