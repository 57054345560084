import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LayersIcon from '@mui/icons-material/Layers';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CameraIcon from '@mui/icons-material/Camera';
import { Appstate } from '../App';
import Balance from './Balance';
import { message } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import Incomes from './Incomes';
import { ethers } from 'ethers';

const Wallet = () => {
  const useAppState = useContext(Appstate);
  const [income, setIncome] = useState({
    rank: 0
  });
  const [loading, setLoading] = useState(false);
  const [left, setLeft] = useState(0);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    async function getData() {
      let income = await useAppState.contract.rewardInfo(useAppState.id);
      setIncome({
        rank: useAppState.convert(income.rankIncome),
      })
     
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  const withdraw = async () => {
    setLoading(true);
    try {
      let tx = await useAppState.contract.claim(ethers.utils.parseUnits(amount.toString(), "mwei"));
      await tx.wait();
      message.success("Sucessfully Received")
    } catch (error) {
      message.error(error.reason)
      console.log(error)
    }
    setLoading(false);
  }

  return (
    <div className="flex justify-center p-4">
    <div className="md:w-4/5 w-full font-semibold">
      <Header />
      <Balance />
      <div className="mt-6 w-full bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
        <p className='w-full text-center font-bold text-lg'>Wallet</p>
          <div className="mt-2 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
            <span className='flex items-center'>
              <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
                <MilitaryTechIcon />
              </span> 
              Rank Bonus
            </span>
            <span>${income.rank}</span>
          </div>

          <input className='mt-3 w-full rounded-sm outline-none text-gray-800 p-2' value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Enter Amount' />

          <button onClick={withdraw} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading ? <TailSpin height={22} color='white' /> : "Recieve"}</button>

      </div>

      <Incomes />

    </div>
    </div>
  )
}

export default Wallet